<template>
  <v-dialog v-model="showModal">
    <v-card class="card-container">
      <br>
      <h2 class="modal-title-style">
        <v-icon class="modal-icon-style">
          mdi-qrcode
        </v-icon>
        Add QR Code
        <v-icon
          class="close-button"
          @click="showModal = false"
        >
          mdi-window-close
        </v-icon>
      </h2>
      <br>
      <div class="image-container">
        <img
          v-if="isContentImgOrGif(contentDetails.content_type)"
          id="image"
          ref="mainImage"
          :src="getFilePath(contentDetails.file_path)"
          class="main-image"
          @load="onImageLoad"
        >
        <video
          v-if="!isContentImgOrGif(contentDetails.content_type)"
          id="video"
          ref="mainVideo"
          class="main-image"
          loop
          autoplay
          @loadedmetadata="onVideoLoad"
        >
          <source
            :src="getFilePath(contentDetails.file_path)"
            type="video/mp4"
          >
        </video>
        <div
          ref="qrCode"
          class="qr-code"
          :style="{ top: qrCodePosition.y + 'px', left: qrCodePosition.x + 'px' }"
          @mousedown.prevent="startDrag"
        >
          <img
            :src="qrCodeImage"
            alt="QR Code"
          >
        </div>
      </div>
      <br>
      <v-form
        ref="form"
        v-model="valid"
      >
        <v-container fluid>
          <v-row
            class="fill-height"
            align-content="center"
            justify="center"
          >
            <v-col
              cols="12"
              md="11"
            >
              <v-text-field
                v-model="qrCodeRedirectUrl"
                :rules="[(v) => !!v || 'This field is required']"
                outlined
                dense
                :disabled="qrCodeUrlType === true"
                filled
                label="QR Code Redirect URL"
              />
            </v-col>
            <v-col
              cols="12"
              md="11"
            >
              <div class="header">
                <div class="div-1">
                  <v-checkbox
                    v-model="qrCodeUrlType"
                    label="Internal URL"
                    persistent-hint
                    color="#FF3700"
                    class="hrw-checkbox"
                  />
                </div>
                <div class="div-2">
                  <v-tooltip
                    top
                    color="white"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        v-bind="attrs"
                        color="#37474F"
                        small
                        v-on="on"
                      >
                        mdi-information
                      </v-icon>
                    </template>
                    <span class="tooltip-text">
                      This will direct the scanned user to <br>
                      our page and collect <br>
                      basic information such as <br>
                      <font class="details-style">Name, Phone Number, and Email.</font>
                    </span>
                  </v-tooltip>
                </div>
              </div>
            </v-col>
          </v-row>
          <v-card-actions class="buttons-style">
            <v-spacer />
            <v-btn
              class="mt-2"
              color="red darken-3"
              @click="showModal = false"
            >
              cancel
            </v-btn>
            <v-btn
              class="mt-2"
              color="#FF3700"
              @click="addQrcode()"
            >
              Add
            </v-btn>
            <v-spacer />
          </v-card-actions>
        </v-container>
      </v-form>
    </v-card>
    <centre-spinner :loading="loading" />
  </v-dialog>
</template>

<script>
import qrcode from 'qrcode';
import spinner from 'src/views/dashboard/component/SpinnerCentre';
import { baseUrl, cdnUrl } from 'src/app-globals';
import Constants from 'src/constants';

export default {
  name: 'GenerateQrCode',
  components: {
    'centre-spinner': spinner,
  },
  props: {
    contentDetails: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      showModal: true,
      valid: false,
      qrCodeImage: '',
      isDragging: false,
      initialMouseX: 0,
      initialMouseY: 0,
      initialPositionX: 0,
      initialPositionY: 0,
      qrCodePosition: { x: 0, y: 0 }, // Store positions in pixels
      qrCodeRedirectUrl: baseUrl,
      qrCodeUrlType: true,
      loading: false,
      imageOriginalWidth: 0,
      imageOriginalHeight: 0,
    };
  },
  computed: {
    bannerQrCodeDetails () {
      return this.$store.getters['qrcodebanners/getBannerQrCodeDetails'];
    },
  },
  watch: {
    showModal (newval) {
      if (newval === false) {
        this.$emit('closed');
      }
    },
  },
  async mounted () {
    await this.generateQRCode();
  },
  methods: {
    getFilePath (fileName) {
      return `${cdnUrl}/${fileName}`;
    },
    isContentImgOrGif (contentType) {
      if (contentType === 'image' || contentType === 'gif') {
        return true;
      }
      return false;
    },
    async generateQRCode () {
      this.loading = true;
      try {
        this.qrCodeImage = await qrcode.toDataURL(this.qrCodeRedirectUrl);
        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    },
    async onImageLoad () {
      this.imageOriginalWidth = this.$refs.mainImage.naturalWidth;
      this.imageOriginalHeight = this.$refs.mainImage.naturalHeight;
      await this.loadQrCodePosition('img');
    },
    async onVideoLoad () {
      this.imageOriginalWidth = this.$refs.mainVideo.videoWidth;
      this.imageOriginalHeight = this.$refs.mainVideo.videoHeight;
      await this.loadQrCodePosition('video');
    },

    async loadQrCodePosition (type) {
      const qrCodeBanner = this.bannerQrCodeDetails.find(item => item.bannerContentId === this.contentDetails.id);
      if (qrCodeBanner) {
        // Convert saved positions to pixels based on original image dimensions
        if (type === 'img') {
          this.qrCodePosition.x = (qrCodeBanner.qrCodeXposition / this.imageOriginalWidth) * this.$refs.mainImage.clientWidth;
          this.qrCodePosition.y = (qrCodeBanner.qrCodeYposition / this.imageOriginalHeight) * this.$refs.mainImage.clientHeight;
          this.qrCodeUrlType = qrCodeBanner.qrCodeUrlType === 'internalUri';
          this.qrCodeRedirectUrl = qrCodeBanner.qrCodeUrlType === 'internalUri' ? baseUrl : qrCodeBanner.qrCodeRedirectUrl;
        } else {
          this.qrCodePosition.x = (qrCodeBanner.qrCodeXposition / this.imageOriginalWidth) * this.$refs.mainVideo.clientWidth;
          this.qrCodePosition.y = (qrCodeBanner.qrCodeYposition / this.imageOriginalHeight) * this.$refs.mainVideo.clientHeight;
          this.qrCodeUrlType = qrCodeBanner.qrCodeUrlType === 'internalUri';
          this.qrCodeRedirectUrl = qrCodeBanner.qrCodeUrlType === 'internalUri' ? baseUrl : qrCodeBanner.qrCodeRedirectUrl;
        }
      } else {
        this.qrCodePosition.x = 10; // Default position
        this.qrCodePosition.y = 10; // Default position
      }
    },
    startDrag (event) {
      if (event.target === this.$refs.qrCode || event.target.tagName === 'IMG') {
        this.isDragging = true;
        this.initialMouseX = event.clientX;
        this.initialMouseY = event.clientY;
        this.initialPositionX = this.qrCodePosition.x;
        this.initialPositionY = this.qrCodePosition.y;
        document.addEventListener('mousemove', this.move);
        document.addEventListener('mouseup', this.stopDrag);
      }
    },
    move (event) {
      if (this.isDragging) {
        const deltaX = event.clientX - this.initialMouseX;
        const deltaY = event.clientY - this.initialMouseY;
        this.qrCodePosition.x = this.initialPositionX + deltaX;
        this.qrCodePosition.y = this.initialPositionY + deltaY;
      }
    },
    stopDrag () {
      this.isDragging = false;
      document.removeEventListener('mousemove', this.move);
      document.removeEventListener('mouseup', this.stopDrag);
    },
    addQrcode () {
      if (this.$refs.form.validate() === false) {
        this.$store.dispatch('alert/onAlert', {
          message: 'Please fill the required fields before proceeding.',
          type: Constants.ALERT_TYPE_ERROR,
        }, { root: true });
        return;
      }
      if (this.qrCodeUrlType === false && this.qrCodeRedirectUrl === baseUrl) {
        this.$store.dispatch('alert/onAlert', {
          message: 'External URL and Internal URL should not be the same',
          type: Constants.ALERT_TYPE_ERROR,
        }, { root: true });
        return;
      }
      this.loading = true;
      if (this.isContentImgOrGif(this.contentDetails.content_type) === true) {
        const qrCodeXposition = (this.qrCodePosition.x / this.$refs.mainImage.clientWidth) * this.imageOriginalWidth;
        const qrCodeYposition = (this.qrCodePosition.y / this.$refs.mainImage.clientHeight) * this.imageOriginalHeight;
        this.$store.dispatch('qrcodebanners/addBannerQrCodeDetails', {
          details: {
            qrCodeRedirectUrl: this.qrCodeRedirectUrl,
            qrCodeUrlType: this.qrCodeUrlType === true ? 'internalUri' : 'externalUri',
            bannerContentId: this.contentDetails.id,
            qrCodeXposition: qrCodeXposition,
            qrCodeYposition: qrCodeYposition,
          },
        }).then(() => {
          this.loading = false;
          this.showModal = false;
        }).catch(() => {
          this.loading = false;
        });
      } else {
        const qrCodeXposition = (this.qrCodePosition.x / this.$refs.mainVideo.clientWidth) * this.imageOriginalWidth;
        const qrCodeYposition = (this.qrCodePosition.y / this.$refs.mainVideo.clientHeight) * this.imageOriginalHeight;
        this.$store.dispatch('qrcodebanners/addBannerQrCodeDetails', {
          details: {
            qrCodeRedirectUrl: this.qrCodeRedirectUrl,
            qrCodeUrlType: this.qrCodeUrlType === true ? 'internalUri' : 'externalUri',
            bannerContentId: this.contentDetails.id,
            qrCodeXposition: qrCodeXposition,
            qrCodeYposition: qrCodeYposition,
          },
        }).then(() => {
          this.loading = false;
          this.showModal = false;
        }).catch(() => {
          this.loading = false;
        });
      }
    },
  },
};
</script>

<style scoped>
.image-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.main-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.qr-code {
  position: absolute;
  z-index: 10;
  cursor: move;
  width: 100px;
  height: 100px;
}
.qr-code img {
  width: 100%;
  height: 100%;
}
/* Media Query for Mobile Devices */
@media (max-width: 480px) {
  .qr-code {
    width: 30px;
    height: 30px;
  }
}
/* Media Query for low resolution  Tablets, Ipads */
@media (min-width: 481px) and (max-width: 767px) {
  .qr-code {
    width: 50px;
    height: 50px;
  }
}
/* Media Query for Tablets Ipads portrait mode */
@media (min-width: 768px) and (max-width: 1024px){
  .qr-code {
    width: 60px;
    height: 60px;
  }
}
/* Media Query for Laptops and Desktops */
@media (min-width: 1025px) and (max-width: 1280px){
  .qr-code {
    width: 80px;
    height: 80px;
  }
}
/* Media Query for Large screens */
@media (min-width: 1281px) {
  .qr-code {
    width: 100px;
    height: 100px;
  }
}
.modal-title-style {
  color: #37474F;
  margin-left: 30px;
}

.modal-icon-style {
  color: #37474F;
  font-size: 45px;
}

.close-button {
  float: right;
  margin-right: 20px;
  margin-top: 10px;
  cursor: pointer;
  color: #37474F;
}

.hrw-checkbox {
  margin-top: -45px;
}

::v-deep .hrw-checkbox .v-label {
  color: #37474F;
}

.buttons-style {
  margin-top: -40px;
}

.header {
  width: 100%;
}

.div-1 {
  float: left;
}

.div-2 {
  float: left;
  margin-top: -40px;
  margin-left: 3px;
  cursor: pointer;
}

.details-style {
  color: #FF3700;
}

.tooltip-text {
  color: #37474F;
}
</style>
