<template>
  <section>
    <overlay-alert
      :show-progress="showProgress"
      :process-alert="processAlert"
    />
    <v-alert
      v-if="!permissionCheck('read-board-list') && !permissionCheck('read-group')"
      text
      prominent
      type="warning"
      color="red"
      border="left"
      class="authorization-alert"
    >
      You are not authorized to perform this action. Please contact your administrator.
    </v-alert>
    <v-card
      v-if="permissionCheck('read-board-list') || permissionCheck('read-group')"
      shaped
      color="#ECEFF1"
    >
      <br>
      <base-tree-view-without-actions
        :value="treeData"
        class="my-tree-view-style"
      >
        <span slot-scope="{node, path, tree}">
          <input
            id="myCheck"
            type="checkbox"
            :checked="node.$checked"
            @change="onCheckBoxChange(node, tree, path)"
          >
          {{ node.text }}
        </span>
      </base-tree-view-without-actions>
      <v-card-actions>
        <v-col
          cols="12"
          class="text-right"
        >
          <v-btn
            class="mt-2"
            color="#FF3700"
            :disabled="!isBoardSelected"
            @click="createCampaign()"
          >
            Create
          </v-btn>
        </v-col>
      </v-card-actions>
      <centre-spinner
        :loading="loading"
      />
    </v-card>
    <v-btn
      v-if="!permissionCheck('read-board-list') && !permissionCheck('read-group')"
      color="red darken-3"
      @click="$router.push({ name: 'Campaign List' })"
    >
      Back
    </v-btn>
  </section>
</template>
<script>
  import Constants from 'src/constants';
  import { walkTreeData } from 'he-tree-vue';
  import { getDefaultStructure, addMissingBoards } from 'src/helpers/digi-board-structure-helper';
  import global from 'src/mixins/global';
  import spinner from 'src/views/dashboard/component/SpinnerCentre';
  import moment from 'moment';
  import OverlayAlert from 'src/views/dashboard/component/OverlayAlert';
  import { baseUrl } from 'src/app-globals';

  export default {
    name: 'SelectDigiBoards',
    components: {
      'centre-spinner': spinner,
      'overlay-alert': OverlayAlert,
    },
    mixins: [global],
    props: {
      campaignFormDetails: {
        type: Object,
        required: true,
      },
    },
    data () {
      return {
        selectedDigiBoards: [],
        treeData: [],
        loading: false,
        showProgress: false,
        processAlert: '',
      };
    },
    computed: {
      bannerFiles () {
        return this.$store.getters['campaigns/getBannerFiles'];
      },
      savedStructure () {
        return this.$store.getters['grouping/getSavedStructure'];
      },
      digiBoards () {
        return this.$store.getters['digiboards/getDigiBoards'];
      },
      advancedScheduleData () {
        return this.$store.getters['schedule/getScheduleData'];
      },
      campaignFiles () {
        return this.$store.getters['campaigns/getCampaignFiles'];
      },
      isBoardSelected () {
        if (this.selectedDigiBoards.length > 0) {
          return true;
        }
        return false;
      },
      bannerQrCodeDetails () {
        return this.$store.getters['qrcodebanners/getBannerQrCodeDetails'];
      },
      newlyCreatedBannerDetails () {
        return this.$store.getters['campaigns/getNewlyCreatedBannerDetails'];
      },
    },
    async mounted () {
      this.loading = true;
      this.permissionCheck('read-group') && await this.fetchDigiBoardSavedStructure();
      this.permissionCheck('read-board-list') && await this.fetchDigiBoards();
      this.showBoardTreeList();
      this.loading = false;
    },
    methods: {
      async fetchDigiBoardSavedStructure () {
        await this.$store.dispatch('grouping/fetchSavedStructure');
      },
      async fetchDigiBoards () {
        await this.$store.dispatch('digiboards/fetchDigiBoards');
      },
      showBoardTreeList () {
        if (Object.keys(this.savedStructure).length <= 0) {
          const digiBoards = this.digiBoards;
          this.treeData = getDefaultStructure(digiBoards);
          return;
        }
        const treeData = this.savedStructure.group;
        const digiBoards = this.digiBoards;
        this.treeData = addMissingBoards(treeData, digiBoards);
      },
      onCheckBoxChange (node, tree, path) {
        tree.toggleCheck(node, path);
        const selectedNodes = [];
        tree.walkTreeData((node) => {
          node.$checked && selectedNodes.push(node);
        });
        this.selectedDigiBoards = selectedNodes;
      },
      async linkDigiBoard () {
        if (this.permissionCheck('link-banner-to-board') === false) {
          this.$router.push({ name: 'Campaign List' });
          this.$store.dispatch('alert/onAlert', {
            message: 'You are not authorized to link this content with devices. Please contact your administrator.',
            type: Constants.ALERT_TYPE_INFO,
          });
          return;
        }
        this.processAlert = 'Linking...';
        const finalResult = [];
        walkTreeData(this.treeData, (node) => {
          if (node.$checked && node.id !== undefined) {
            finalResult.push(node.id);
          }
        });
        this.$store.dispatch('publish/addSelectedBoardsForPublish', finalResult);
        for (const banner of this.bannerFiles) {
          await this.$store.dispatch('campaigns/linkCampaignToBoard', {
            banner_id: banner.id,
            digi_boards: finalResult,
          });
        }
        this.processAlert = '';
        this.showProgress = false;
        this.$store.dispatch('alert/onAlert', {
          message: 'Campaign created successfully.',
          type: Constants.ALERT_TYPE_SUCCESS,
        });
        this.$router.push({ name: 'Campaign List' });
        this.$store.dispatch('campaigns/clearBannerFile');
      },
      convertToGmt (date) {
        return moment(date).utc();
      },
      async createCampaign () {
        this.showProgress = true;
        this.processAlert = 'Creating...';
        const schedules = [];
        for (const advancedSchedule of this.advancedScheduleData) {
          schedules.push(advancedSchedule.id);
        }
        const uniqueFileName = [];
        const rotationInterval = [];

        for (const campaignFile of this.campaignFiles) {
          uniqueFileName.push(campaignFile.formData.unique_file_name);
          rotationInterval.push(campaignFile.rotation_interval);
        }
        for (var i = 0; i < this.campaignFiles.length; i++) {
          const qrCodeBanner = this.bannerQrCodeDetails.filter(item => item.bannerContentId === this.campaignFiles[i].formData.id);
          if (qrCodeBanner.length > 0) {
            await this.$store.dispatch('campaigns/createBannerFiles', {
              unique_file_name: uniqueFileName[i],
              board_listed: JSON.stringify(false),
              name: this.campaignFormDetails.campaignName,
              rotation_interval: rotationInterval[i] * 1000,
              start: this.convertToGmt(this.campaignFormDetails.start),
              end: this.campaignFormDetails.end !== null ? this.convertToGmt(this.campaignFormDetails.end) : null,
              schedules: schedules,
            }).then(response => {
              const redirectUrl = qrCodeBanner[0].qrCodeUrlType === 'internalUri' ? `${baseUrl}/qr-code/redirect/banners?bannerId=${this.newlyCreatedBannerDetails.id}&urlType=internal-url&redirectUrl=${baseUrl}/qr-code/redirect/user-details`
              : `${baseUrl}/qr-code/redirect/banners?bannerId=${this.newlyCreatedBannerDetails.id}&urlType=external-url&redirectUrl=${qrCodeBanner[0].qrCodeRedirectUrl}`;
              this.$store.dispatch('qrcodebanners/attachQrCodeWithBanner', {
                qrCodeDetails: {
                  qr_code_redirect_uri: redirectUrl,
                  qr_code_type: qrCodeBanner[0].qrCodeUrlType,
                  qr_code_x_position: qrCodeBanner[0].qrCodeXposition.toString(),
                  qr_code_y_position: qrCodeBanner[0].qrCodeYposition.toString(),
                },
                bannerId: this.newlyCreatedBannerDetails.id,
              });
            });
          } else {
            await this.$store.dispatch('campaigns/createBannerFiles', {
              unique_file_name: uniqueFileName[i],
              board_listed: JSON.stringify(false),
              name: this.campaignFormDetails.campaignName,
              rotation_interval: rotationInterval[i] * 1000,
              start: this.convertToGmt(this.campaignFormDetails.start),
              end: this.campaignFormDetails.end !== null ? this.convertToGmt(this.campaignFormDetails.end) : null,
              schedules: schedules,
            });
          }
        }
        const banners = [];
        for (const banner of this.bannerFiles) {
          banners.push(banner.id);
        }
        await this.$store.dispatch('campaigns/createCampaign', {
          name: this.campaignFormDetails.campaignName,
          comment: this.campaignFormDetails.comment,
          start: this.convertToGmt(this.campaignFormDetails.start),
          end: this.campaignFormDetails.end !== null ? this.convertToGmt(this.campaignFormDetails.end) : null,
          global: this.campaignFormDetails.global,
          schedules: schedules,
          banners: banners,
        }).then(response => {
          this.$store.dispatch('campaigns/clearCampaignFile');
          this.$store.dispatch('campaigns/clearCampaignName');
          this.$store.dispatch('qrcodebanners/clearAllBannerQrCodeDetails');
          this.$store.dispatch('schedule/clearAdvancedSchedule');
          this.linkDigiBoard();
        }).catch(() => {
          this.$router.push({ name: 'Campaign List' });
        });
      },
    },
  };
</script>
<style scoped>
.my-tree-view-style {
  margin-left: 30px;
}
.authorization-alert {
  font-family: 'Times New Roman', Times, serif;
  font-size: 16px;
}
</style>
